body {
  margin: 0;
  font-family: 'Roboto','Open Sans', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  /* background-color: #39394B; */
  background-color:var(--body-bg-color);
  /* color: #232333; */
  color:var(--body-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *:before, *:after {
  box-sizing: border-box;
}

ul, ol {
  margin-top: 0;
  margin-bottom: 10px;
}

a {
  /* color: #0E71EB; // ORIG BLUE */
  color: var(--sidebar-button-color);
  text-decoration: none;
}
a:hover, a:focus {
  color: #3e8def;
}
a:hover {
  text-decoration: underline;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit;
}

h3 {
  font-size: 16px;
}
